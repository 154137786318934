import '../project/polyfill'
import App from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import withRedux from 'next-redux-wrapper'
import withReduxSaga from 'next-redux-saga'
import '../project/project-components'
import createStore from '../common/store'
import Toast from '../components/toast'
import _data from '../common/utils/_data'
import 'ionicons/dist/css/ionicons.css'

import '../styles/Global.scss'
import { Store } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'
import LanguageHandler from '../common/LanguageHandler'
import AuthProvider from '../components/AuthProvider'
import Navbar from 'components/NavBar'
import { LinkProvider } from 'common/providers/LinkProvider'
import { AuthPageProvider } from '../common/providers/AuthPageProvider'
import vectorBg from '../public/images/vector-bg-large.png'
import luLogo from '../public/images/lu-logo.svg'

let initialRender = false
const store = () => createStore().store
const persistor = createStore().persistor

const LinkComponent = ({ href, className, children }) => (
  <Link href={href} className={className}>
    {children}
  </Link>
)

class MyApp extends App<{ store: Store }> {
  constructor(props) {
    super(props)
    // If you are using SSR do not use this function, the token should already be retrieved by the server
    if (
      typeof window !== 'undefined' &&
      !this.props.store.getState().clientLoaded
    ) {
      // If you are using a static site you will have to use this approach for setting the token, otherwise it's handled
      // const token = API.getStoredToken(); // Retrieve token cookie from browser cookies
      // const refreshToken = API.getStoredRefreshToken(); // Retrieve token cookie from browser cookies
      // this.props.store.dispatch(AppActions.startup({ token, refreshToken, clientLoaded: true }, {
      //     onSuccess: () => {
      //
      //     },
      // })); // Post startup action with token and locale
      //    otherwise just set the token
      _data.setToken(this.props.store.getState().token)
    }
  }

  onFirstRender = () => {
    if (this.props.router.asPath.includes('E2E=1') || Constants.E2E) {
      // to force web app into e2e mode, the e2e tests append a get param
      if (this.props.router.asPath.includes('E2E_NAMESPACE')) {
        Constants.E2E_NAMESPACE = Utils.fromParam(
          this.props.router.asPath,
        ).E2E_NAMESPACE
      }
      Constants.E2E = true
      if (typeof window !== 'undefined') {
        document.body.classList.add('e2e')
      }
    }
    // API.auth.Cognito.init(Project.cognitoWeb)

    const { store } = this.props
    initialRender = true
    const locale = store.getState().locale
    if (locale) {
      Strings.setLanguage(locale)
    }
  }

  render() {
    const { Component, pageProps, store } = this.props
    if (!initialRender) {
      // Ensure we set the locale before rendering anything
      this.onFirstRender()
    }

    return (
      <Provider store={store}>
        {/* @ts-ignore */}
        <PersistGate persistor={persistor} loading={null}>
          <LanguageHandler>
            <LinkProvider LinkComponent={LinkComponent}>
              <AuthPageProvider
                tagline='Find your clients'
                logoBackLink={Project.cwp}
                backgroundImageSrc={vectorBg.src}
                logoSrc={luLogo.src}
              >
                <AuthProvider>
                  <Navbar />
                  <Component {...pageProps} />
                </AuthProvider>
                <div id='modal' />
                <div id='confirm' />
                <div id='alert' />
                <div id='toast'>
                  <Toast />
                </div>
                {E2E && (
                  <React.Fragment>
                    <div className='e2e' id='e2e-request' />
                    <div className='e2e' id='e2e-error' />
                  </React.Fragment>
                )}
              </AuthPageProvider>
            </LinkProvider>
          </LanguageHandler>
        </PersistGate>
      </Provider>
    )
  }
}

export default global.__JEST__ ? null : withRedux(store)(withReduxSaga(MyApp))
