import classNames from 'classnames'
import { Navbar } from '../common/components/Nav/Navbar'
import { useRouter } from 'next/router'

const EXCLUDED_ROUTES = ['/', ...Constants.authRoutes]
const DASHBOARD_ROUTES = ['/account']

type NavBarProps = {
  isDashboard?: boolean
  title?: string
  setIsSidebarOpen?: (isOpen: boolean) => void
  className?: string
}

const NavBar = ({
  title,
  setIsSidebarOpen,
  className,
  isDashboard,
}: NavBarProps) => {
  const { asPath } = useRouter()
  const pathname = asPath.split('?')[0]

  if (EXCLUDED_ROUTES.includes(pathname)) {
    return null
  }

  if (DASHBOARD_ROUTES.includes(pathname) && !isDashboard) {
    return null
  }

  return (
    <Navbar
      isSlimDropdown
      className={classNames(
        'bg-white xl:bg-transparent xl:border-none',
        className,
      )}
      dropdownOptions={[
        {
          isLink: true,
          label: 'Settings',
          value: '/settings',
          icon: '/images/nav-dropdown/cog.svg',
        },
      ]}
    >
      <h1 className='text-3xl	text-lue-blue-700 mb-0 font-medium hidden xl:block'>
        {title}
      </h1>
      <button
        className='xl:hidden'
        onClick={() => {
          setIsSidebarOpen?.(true)
        }}
      >
        <img src='/images/sidebar/hamburger.svg' />
      </button>
    </Navbar>
  )
}

export default NavBar
